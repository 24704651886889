////
/// Text transform (Automatic)
/// @author Annaliet Iglesias
/// @author Victor Cruz
/// @access public
/// @group cf-helpers
////


/* Text transform (Automatic)
 -------------------------------------------------------------- */

@each $value in capitalize, uppercase, lowercase {
  .#{$prefix-helpers}-#{$value} {
    text-transform : #{$value};
  }
}

// Example Generate:

//.cf-capitalize {
//  text-transform: capitalize;
//}
//
//.cf-uppercase {
//  text-transform: uppercase;
//}
//
//.cf-lowercase {
//  text-transform: lowercase;
//}
//@import '~material-design-icons/iconfont/material-icons.css';
// Import Fuse core library
@import "@fuse/scss/core";
// cf helpers
@import "./assets/styles/helpers/cf";
// regular style toast
@import "~ngx-toastr/toastr.css";
// Import app.theme.scss
@import "app/app.theme";

body.theme-default .active.accent {
  background-color: #F6A006 !important;

  & {
    mat-icon, span {
      color: #000;
    }
  }
}

.confirmed {
  color: #43A047;
}

.registered {
  color: #2D323E;
}

.rejected {
  color: #F44336;
}

.exceptional {
  color: #999999;
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
